import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

import { ListVehicleState } from "../../../../../listvehiclestate/index";

export const StateDetails = ({ selectedId }) => {
  return (
    <div className="sticky top-0 h-full w-full md:top-[70px]">
      <Scrollbars>
        <ListVehicleState
          containerId={selectedId}
          listVehicleId={selectedId}
          showStickyHeader={false}
        />
      </Scrollbars>
    </div>
  );
};
