import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import clsx from "clsx";
import { compose } from "redux";

import { withConfig } from "../../../config";
import { ListTypes } from "../../../constants";
import { download, formatDateTZ } from "../../../helpers/functions";
import {
  updateCriteriaFavorites,
  updateCriteriaInterestWithNoBid,
} from "../../listvehicles-filter/index";
import * as selectors from "../selectors";
import { CircleWithLabel } from "./CircleWithLabel";
import { CountDownLabel } from "./CountDownLabel";
import { ListInfo } from "./ListInfo";

import { sortBy } from "lodash";
import moment from "moment";

const ListItem = (props) => {
  const {
    list,
    config,
    listId,
    updateCriteriaInterestWithNoBid,
    updateCriteriaFavorites,
  } = props;

  const { id, stop, first_auction_vehicle_stop_time, counters, list_info } =
    list;
  const sorted_counters = sortBy(counters, ["count"], ["asc"]);
  const { t } = useTranslation();
  const showAuctionStartTimer =
    first_auction_vehicle_stop_time && moment(stop).diff(moment()) > 0;

  // TODO: Check if unused?
  const showListDetails = () => {
    props.onSelectList(props.list.id);
  };

  const onPdfClick = (e) => {
    e.stopPropagation();
    download(`sales/portal/downloadList/${listId}`, true);
  };

  const onCounterClick = (code) => {
    if (code === "OFFERED") {
      updateCriteriaInterestWithNoBid(listId, true);
    } else if (code === "FAVOURITES") {
      updateCriteriaFavorites(listId, true);
    }
  };

  const ItemCard = ({ name, title }) => {
    return (
      <div className="flex justify-between">
        <h3 className="text-[16px] font-semibold text-highlightColor ">
          <span>{name}</span>

          {config.list_download_pdf && (
            <i
              className="fa fa-file-pdf-o ml-3 pt-1 text-[16px]"
              onClick={onPdfClick}
            />
          )}
        </h3>

        <div
          className={clsx(
            "u-label badge ml-1 self-start !rounded-[3px] !px-2 !py-1 text-white ",
            {
              "bg-fixedPriceBadge": list.type === ListTypes.FIXED,
              "bg-blindBiddingBadge": list.type === ListTypes.TENDER,
              "bg-liveAuctionBadge": list.type === ListTypes.LIVE_AUCTION,
            }
          )}
        >
          <div className="flex items-center">
            <i
              className={clsx("fa mr-2 text-[18px] text-white", {
                "fa-handshake-o": list.type === ListTypes.FIXED,
                "fa-gavel":
                  list.type === ListTypes.TENDER ||
                  list.type === ListTypes.LIVE_AUCTION,
              })}
            />{" "}
            <span className="text-nowrap font-bold">{title}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderListBadge = (list) => {
    switch (list.type) {
      case ListTypes.FIXED:
        return <ItemCard name={list.name} title={t("Fixed price")} />;
      case ListTypes.TENDER:
        return <ItemCard name={list.name} title={t("Blind bidding")} />;
      case ListTypes.LIVE_AUCTION:
        return <ItemCard name={list.name} title={t("Live Auction")} />;
      default:
        throw new Error(`Unknown list type: ${list.type}`);
    }
  };

  return (
    <div
      className="item item--hover color-primary mb-2 cursor-pointer space-y-5 bg-white p-2"
      onClick={() => props.onListSelect(id)}
    >
      {renderListBadge(list)}

      <div className="my-1 flex justify-between">
        <div className="flex basis-1/2 flex-col space-y-1">
          {list_info &&
            list_info.map((info, index) => <ListInfo {...info} key={index} />)}
        </div>

        <div className="flex basis-1/2 items-start justify-end space-x-3">
          {counters &&
            sorted_counters.map((counter, index) => (
              <CircleWithLabel
                size={60}
                text={counter.count}
                title={counter.name}
                color={counter.color}
                key={index}
                code={counter.key}
                onClick={onCounterClick}
              />
            ))}
        </div>
      </div>

      <div className="space-y-1.5">
        {showAuctionStartTimer && (
          <div className="flex items-center justify-between">
            <div className="text-primary">
              <i className="fa fa-hourglass-start mr-2 self-center"></i>
              {formatDateTZ(
                first_auction_vehicle_stop_time,
                "DD/MM/YYYY HH:mm"
              )}
            </div>
            <CountDownLabel
              date={first_auction_vehicle_stop_time}
              showStarted={true}
            />
          </div>
        )}

        {stop && (
          <div className="flex items-center justify-between">
            <div className="text-primary">
              <i className="fa fa-hourglass-end mr-2 self-center"></i>
              {formatDateTZ(props.list.stop, "DD/MM/YYYY HH:mm")}
            </div>
            {/*TODO: occasional error  appears about stop not being defined*/}
            <CountDownLabel date={stop} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    list: selectors.listById(state, ownProps.listId),
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps, {
    updateCriteriaInterestWithNoBid,
    updateCriteriaFavorites,
  })
)(ListItem);

export { ConnectedComponent as ListItem };
