import React from "react";

import clsx from "clsx";

import { ErrorBoundary } from "../components";
import { Footer } from "../components/web/application/Footer";
import { Header } from "../components/web/application/Header";

export const Template = (props) => {
  const {
    headerVisible = true,
    footerVisible = true,
    isFullWidth = false,
  } = props;

  const containerStyle = clsx(
    isFullWidth ? "w-full" : "md:container",
    "mx-auto",
    "px-[15px]",
    "xl:px-[30px]",
    "box-border"
  );

  return (
    <ErrorBoundary>
      <div className="flex h-dvh overflow-hidden">
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {headerVisible && <Header containerStyle={containerStyle} />}
          <main className="grow">
            <div className={`${containerStyle} h-full pb-[14px]`}>
              {props.children}
            </div>
          </main>
          {footerVisible && <Footer />}
        </div>
      </div>
    </ErrorBoundary>
  );
};
