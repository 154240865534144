import { RSAA } from "redux-api-middleware";

import { API_ENDPOINTS } from "../../api/endpoints";
import { entityActions } from "../../entities/actions";
import {
  combineIds,
  getListIdFromListVehicleId,
  getVehicleIdFromListVehicleId,
} from "../../helpers/functions";
import { mapVehicleState } from "./mappers";
import { listVehicleStateTypes } from "./types";

import { map, mapKeys } from "lodash";
import _ from "lodash";

export const listVehicleStateFetch = (
  listVehicleId,
  sendListInformation = false
) => {
  const vehicleId = getVehicleIdFromListVehicleId(listVehicleId);
  let listId = 0;

  return (dispatch) => {
    if (sendListInformation) {
      listId = getListIdFromListVehicleId(listVehicleId);
      console.log(getListIdFromListVehicleId(listVehicleId));
    }
    return dispatch({
      [RSAA]: {
        endpoint: !sendListInformation
          ? `sales/api/v2/states/${vehicleId}/`
          : `sales/api/v2/states/${vehicleId}/?listId=${listId}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: listVehicleStateTypes.FETCH_REQUEST,
            payload: {
              listVehicleId,
            },
          },
          {
            type: listVehicleStateTypes.FETCH_SUCCESS,
            payload: (action, state, res) =>
              onListVehicleStateFetchSuccess(dispatch, res, listVehicleId),
          },
          {
            type: listVehicleStateTypes.FETCH_FAILURE,
            payload: (action, state, res) =>
              onListVehicleStateFetchError(dispatch, res, listVehicleId),
          },
        ],
      },
    });
  };
};

// TODO: check if the endpoint is deprecated
export const vehicleStateFetch = (id, search) => {
  const vehicleId = getVehicleIdFromListVehicleId(id);

  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_ENDPOINTS.FRONTPAGE_STATE}${vehicleId}/${search}`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: false },

        types: [
          {
            type: listVehicleStateTypes.FETCH_REQUEST,
            payload: {
              vehicleId: id,
            },
          },
          {
            type: listVehicleStateTypes.FETCH_SUCCESS,
            payload: (action, state, res) =>
              onListVehicleStateFetchSuccess(dispatch, res, id),
          },
          {
            type: listVehicleStateTypes.FETCH_FAILURE,
            payload: (action, state, res) =>
              onListVehicleStateFetchError(dispatch, res, id),
          },
        ],
      },
    });
  };
};

const onListVehicleStateFetchSuccess = (dispatch, res, listVehicleId) => {
  return res.json().then((data) => {
    let result = {
      vehicle: {
        [listVehicleId]: mapVehicleState(data),
      },
    };
    dispatch(entityActions.updateEntities(result));

    return {
      listVehicleId: listVehicleId,
      receivedAt: Date.now(),
    };
  });
};

const onListVehicleStateFetchError = (dispatch, res, listVehicleId) => {
  return {
    listVehicleId,
    message: res.statusText,
  };
};

export const listVehicleStateClosed = (listVehicleId) => {
  return {
    type: listVehicleStateTypes.CLOSED,
    listVehicleId,
  };
};

export const listVehicleBasketFetch = (listVehicleId) => {
  const listId = getListIdFromListVehicleId(listVehicleId);
  const vehicleId = getVehicleIdFromListVehicleId(listVehicleId);
  return basketFetchV1(
    listId,
    vehicleId,
    `sales/api/v2/basket/?listId=${listId}`
  );
};

export const vehicleBasketFetch = (listId, vehicleId) => {
  return basketFetchV1(
    listId,
    vehicleId,
    // TODO: check the response from the backend as "vehicleId" does not seem to have any effect
    `sales/api/v2/basket/?vehicleId=${vehicleId}`
  );
};

const basketFetchV1 = (listId, vehicleId, endpoint) => {
  const listVehicleId = combineIds(listId, vehicleId);
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: endpoint,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          {
            type: listVehicleStateTypes.FETCH_BASKET_REQUEST,
            payload: {
              listId,
            },
          },
          {
            type: listVehicleStateTypes.FETCH_BASKET_SUCCESS,
            payload: (action, state, res) =>
              onlistVehicleBasketFetchSuccess(dispatch, res, listVehicleId),
          },
          {
            type: listVehicleStateTypes.FETCH_BASKET_FAILURE,
          },
        ],
      },
    });
  };
};

const onlistVehicleBasketFetchSuccess = (dispatch, res, listVehicleId) => {
  return res.json().then((result) => {
    let vehicles = mapKeys(
      map(
        _.filter(
          result,
          (vehicle) =>
            listVehicleId === `${vehicle.list_id}_${vehicle.vehicle_id}`
        ),
        (data) => ({
          list_vehicle_id: `${_.get(data, "list_id")}_${_.get(
            data,
            "vehicle_id"
          )}`,
          basket_state: _.get(data, "state"),
          dealer_state: _.get(data, "dealer_state"),
          basket_price: _.get(data, "price"),
          comment: _.get(data, "comment") || "",
          basket_maximum_price: _.get(data, "maximum_price") || 0,
          highest: _.get(data, "highest") || false,
        })
      ),
      function (value) {
        return value.list_vehicle_id;
      }
    );

    if (_.keys(vehicles).length > 0) {
      dispatch(
        entityActions.updateEntities({
          vehicle: vehicles,
        })
      );
    }
  });
};

export const listVehiclesStateCleanUp = (invalidListVehicleIds) => {
  return (dispatch) => {
    return dispatch({
      type: listVehicleStateTypes.CLEANUP,
      payload: {
        invalidListVehicleIds,
      },
    });
  };
};
