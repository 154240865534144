export const API_ENDPOINTS = {
  DOCUMENT: "manager/api/v1/sync/request/document/",
  API_TOKEN_AUTH: "sales/api/v2/api-token-auth/",
  API_TOKEN_BY_CODE: "/sales/api/v2/api-token-code/",
  BASKET: "sales/api/v2/basket/",
  BASKET_CONFIRM: "sales/api/v2/basket/0/confirm/",
  COLLEAGUES: "sales/api/v2/colleagues/",
  COLLEAGUES_INVITE: "sales/api/v2/colleagues/0/invite/",
  COMPANIES_FOR_COMPANY: "sales/api/v2/companies-for-company/",
  CONTENT: "sales/api/v2/content/",
  WARNINGS: "sales/api/v2/content/9/",
  COUNTRIES: "sales/api/v2/countries/",
  DEVICE: "sales/api/v2/device/",
  FAVOURITE: "sales/api/v2/favourite/",
  FILTERS_COMPANIES: "sales/api/v2/filters-companies/",
  FILTERS_MAKES: "sales/api/v2/filters-makes/",
  FILTERS: "sales/api/v2/filters/",
  FRONTPAGE_STATE: "sales/api/v2/frontpage-state/",
  LIST_HISTORY: "sales/api/v2/list-history/",
  LIST_VEHICLE: "sales/api/v2/list-vehicle/",
  LISTS: "sales/api/v2/lists/",
  MAILSETTINGS: "sales/api/v2/mailsettings/",
  NO_INTEREST: "sales/api/v2/no-interest/",
  OFFER_VEHICLE: "sales/api/v2/offer-vehicle/",
  OFFERS: "sales/api/v2/offers/",
  PROFILES: "sales/api/v2/profiles/",
  RESET_PASSWORD: "sales/api/v2/profiles/1/reset_password/",
  SET_PASSWORD: "sales/api/v2/profiles/1/set_password/",
  REGISTRATION_CONFIRMATION: "sales/api/v2/registration-confirmation/0/",
  REGISTRATIONS: "sales/api/v2/registrations/",
  STATISTICS: "sales/api/v2/statistics/",
  VEHICLES: "sales/api/v2/vehicles/",
  SUPPLIERS: "sales/dealer/api/contact/",
};
