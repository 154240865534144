import React from "react";

import { LoadingIndicator } from "./LoadingIndicator";

export const LoadingContainer = ({ loading, children }) => (
  <div className="relative h-full">
    {loading && (
      <div className="loadingContainerOverlay flex items-center justify-center">
        <LoadingIndicator loading={loading} />
      </div>
    )}
    <div className="h-full">{children}</div>
  </div>
);
