import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadingIndicator, Template } from "../components";
import { getError, getIsAuthenticating } from "../features/authentication";
import { loginByCode } from "../features/authentication/authenticationSlice";
import { isAuthenticated } from "../features/session";
import { routes } from "./navigation/routes";

export const LoginByCode = ({ code }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const authenticating = useSelector(getIsAuthenticating);
  const authenticated = useSelector(isAuthenticated);
  const error = useSelector(getError);

  const navigateTo = location.state?.from?.pathname || routes.DASHBOARD;

  useEffect(() => {
    if (!authenticated) {
      dispatch(loginByCode({ code: code }));
    }
    if (authenticated) {
      navigate(navigateTo);
    }
  }, [authenticated, navigateTo]);

  return (
    <Template>
      <div className="mt-10">
        <LoadingIndicator loading={authenticating} />
        {error && <span className="text-danger">ERROR: {error.message}</span>}
      </div>
    </Template>
  );
};
