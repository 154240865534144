import React from "react";

export const ListInfo = (props) => {
  const { description, value } = props;
  return (
    <div className="flex text-sm">
      <div className="grow-0 basis-3/5 text-primary">{description}</div>
      <div className="w-[40px] self-start rounded bg-primary py-0.5 text-center text-white">
        <span>{value}</span>
      </div>
    </div>
  );
};
