import React, { useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { compose } from "redux";

import { routes } from "../../../_web/navigation/routes";
import { Button } from "../../../components/web/Button";
import { withConfig } from "../../../config";
import { ListTypes } from "../../../constants";
import { withRouter } from "../../../helpers/utils";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { ConfirmButton, ConfirmReminder } from "../../cart";
import { ListDetailInfo } from "../../lists/_web/ListDetailInfo";
import * as listSelectors from "../../lists/selectors";
import { Filter } from "../../listvehicles-filter";
import { resetCriteria as resetFilterCriteria } from "../../listvehicles-filter/actions";
import { ListVehicles } from "../../listvehicles/_web/ListVehicles";
import { listVehiclesFetchAndBasket } from "../../listvehicles/actions";
import * as listVehicleSelectors from "../../listvehicles/selectors";
import { ListVehicleState } from "../../listvehiclestate";
import { listVehicleSelect, toggleListVehicleState } from "../actions";
import * as selectors from "../selectors";
import { DefaultListVehiclesHeader } from "./DefaultListVehiclesHeader";
import { LiveAuctionListVehiclesHeader } from "./LiveAuctionListVehiclesHeader";

import { startsWith } from "lodash";

const xxl = 1600;

const ListvehiclesContainer = (props) => {
  const {
    listId,
    listType,
    listVehicleId,
    config,
    sendListInformationByRequest,
  } = props;
  const { vehicle_bidding_cart } = config;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [windowWidth] = useWindowSize();

  const detailsVisible = windowWidth >= xxl;

  const onListVehicleClick = (listVehicleId) => {
    if (!detailsVisible) {
      props.listVehicleSelect(listVehicleId, true);
    } else {
      props.listVehicleSelect(listVehicleId, false);
    }
    if (props.onSelectedVehicleChanged) {
      props.onSelectedVehicleChanged();
    }
  };

  const onShowState = (listVehicleId) => {
    props.listVehicleSelect(listVehicleId, true);
  };

  const goBack = () => {
    const { listId, router } = props;
    props.resetFilterCriteria(listId);

    if (router.location.state && router.location.state.routed) {
      navigate(-1);
    } else {
      navigate(routes.DASHBOARD);
    }
  };

  // Scroll to top on initial load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showDefaultListVehiclesHeader =
    listType !== ListTypes.LIVE_AUCTION && listType !== "";
  const showLiveAuctionListVehiclesHeader =
    listType === ListTypes.LIVE_AUCTION && listType !== "";

  const showBiddingCart =
    vehicle_bidding_cart && listType !== ListTypes.LIVE_AUCTION;

  return (
    <div className="mb-5 flex h-full gap-x-3">
      <div className="hidden h-full xl:block xl:w-3/12 3xl:w-2/12">
        <div className="bg-normal sticky top-0 flex h-full w-full flex-col md:top-[70px]">
          <div className="mb-3">
            <div className="flex flex-col items-center space-y-3 bg-white p-3">
              <ListDetailInfo listId={listId} />
              <Button
                onClick={goBack}
                classNames="flex items-center text-nowrap text-sm font-semibold"
              >
                <i className="fa fa-chevron-left mr-2"></i>
                {t("Back")}
              </Button>
            </div>

            {showBiddingCart && (
              <div className="mt-3 space-y-2 bg-white p-3">
                <ConfirmButton listId={listId} />
                <ConfirmReminder listId={listId} />
              </div>
            )}
          </div>
          <Scrollbars>
            <Filter
              listId={listId}
              onCriteriaChanged={() => props.listVehiclesFetchAndBasket(listId)}
            />
          </Scrollbars>
        </div>
      </div>

      <div className="flex h-full w-full flex-col 2xl:w-5/12">
        {showDefaultListVehiclesHeader && (
          <DefaultListVehiclesHeader
            listId={listId}
            loading={props.listVehiclesLoading}
            onBack={goBack}
            cartConfirm={vehicle_bidding_cart}
            count={props.count}
            onFilterChanged={() => props.listVehiclesFetchAndBasket(listId)}
          />
        )}

        {showLiveAuctionListVehiclesHeader && (
          <LiveAuctionListVehiclesHeader
            listId={listId}
            loading={props.listVehiclesLoading}
            onBack={goBack}
            onFilterChanged={() => props.listVehiclesFetchAndBasket(listId)}
          />
        )}

        <ListVehicles
          listId={listId}
          selectedListVehicle={listVehicleId}
          onClick={onListVehicleClick}
          onShowState={detailsVisible ? onShowState : null}
          fetchData={false}
        />
      </div>

      <div className="hidden h-full 3xl:block 3xl:w-5/12">
        <div className="sticky top-0 h-full w-full md:top-[70px]">
          <Scrollbars>
            <ListVehicleState
              containerId={listVehicleId}
              listVehicleId={listVehicleId}
              showStickyHeader={false}
              sendListInformationByRequest={sendListInformationByRequest}
            />
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { listId } = ownProps;
  const selectedId = selectors.getSelectedListVehicleId(state).toString();
  const list = listSelectors.listById(state, listId);

  return {
    listId,
    listType: list ? list.type : "",
    listVehicleId: startsWith(selectedId, listId.toString()) ? selectedId : 0,
    listVehiclesLoading: listVehicleSelectors.getInitialLoading(state, listId),
    count: listVehicleSelectors.getTotalCount(state, listId),
  };
};

const ConnectedComponent = compose(
  withRouter,
  withConfig,
  connect(mapStateToProps, {
    resetFilterCriteria,
    listVehicleSelect,
    toggleListVehicleState,
    listVehiclesFetchAndBasket,
  })
)(ListvehiclesContainer);

export { ConnectedComponent as ListVehiclesContainer };
