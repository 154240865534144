import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { compose } from "redux";

import {
  InfoContainer,
  LoadingContainer,
  LoadingIndicator,
} from "../../../components";
import { Button } from "../../../components/web/Button";
import { withConfig } from "../../../config";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { basketFetchMore, basketFetchWebTest } from "../../basket/actions";
import * as basketSelectors from "../../basket/selectors";
import {
  ClearFilters,
  Filter,
  FilterButton,
  FilterSummary,
  hasActiveCriteria,
} from "../../listvehicles-filter";
import { getHistorySorters, SortDropDown } from "../../listvehicles-sort";
import { ListVehicleState } from "../../listvehiclestate";
import { historyVehicleSelect } from "../actions";
import { ExcelDownloadByFilter } from "./ExcelDownloadByFilter";
import { HistoryVehicleItem } from "./HistoryVehicleItem";

// TODO: fix with CSS?
const xxl = 1600;

const ListVehicles = ({
  loading,
  vehicleIds,
  loadMore,
  more,
  onListVehicleClick,
  selectedVehicleId,
  offset,
}) => {
  const isLoading = vehicleIds.length > 0;

  return (
    <LoadingContainer loading={loading && isLoading && offset === 0}>
      <Scrollbars>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={more}
          useWindow={false}
          threshold={800}
          loader={<LoadingIndicator key="loader" loading={isLoading} />}
        >
          <div className="space-y-3">
            {vehicleIds.map((id) => (
              <HistoryVehicleItem
                key={id}
                listVehicleId={id}
                onClick={onListVehicleClick}
                selected={selectedVehicleId === id}
              />
            ))}
          </div>
        </InfiniteScroll>
      </Scrollbars>
    </LoadingContainer>
  );
};

const HistorySidebar = ({ goBack, config, handleCriteriaChanged }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-normal sticky top-0 z-10 flex h-full w-full flex-col md:top-[70px]">
      <div className="mb-3">
        <div className="flex items-center justify-center space-x-3 bg-white p-3">
          <Button
            onClick={goBack}
            classNames="text-nowrap text-sm font-semibold"
          >
            <i className="fa fa-chevron-left mr-2"></i>
            {t("Back")}
          </Button>
          {config.history_download_excel && <ExcelDownloadByFilter />}
        </div>
      </div>
      <Scrollbars>
        <Filter
          listId={-1}
          onCriteriaChanged={handleCriteriaChanged}
          showCounters={false}
        />
      </Scrollbars>
    </div>
  );
};

const VehiclesListHeader = ({
  goBack,
  totalCount,
  loading,
  handleFilterChange,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-normal sticky top-0 z-10 mb-3 md:top-[70px]">
      <div className="bottom-shadow bg-white p-2">
        <div className="flex items-center justify-between space-x-1">
          <div className="xl:hidden">
            <Button
              onClick={goBack}
              classNames="text-nowrap text-sm font-semibold"
            >
              <i className="fa fa-chevron-left mr-2"></i>
              {t("Back")}
            </Button>
          </div>

          <div className="flex space-x-3">
            <div className="uppercase">
              <span className="hidden lg:block">
                {totalCount ? totalCount : null} {t("results")}
              </span>
              <span className="hidden sm:block lg:hidden">
                # {totalCount ? totalCount : null}
              </span>
            </div>

            <div className="hidden md:block">
              <ClearFilters listId={-1} onClearFilters={handleFilterChange} />
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <div className="xl:hidden">
              <FilterButton
                listId={-1}
                onCriteriaChanged={handleFilterChange}
                onClearFilters={handleFilterChange}
              />
            </div>
            <div>
              <SortDropDown
                listId={-1}
                sorters={getHistorySorters()}
                defaultSorterId={2}
                onSortChanged={handleFilterChange}
              />
            </div>
          </div>
        </div>
        <div className="mt-2 hidden xl:flex">
          <FilterSummary listId={-1} onCriteriaChanged={handleFilterChange} />
        </div>
      </div>
      <LoadingIndicator loading={loading} />
    </div>
  );
};

const HistoryVehicles = (props) => {
  const navigate = useNavigate();
  const [windowWidth] = useWindowSize();
  const { t } = useTranslation();

  const detailsVisible = windowWidth >= xxl;

  const onListVehicleClick = (listVehicleId) => {
    if (!detailsVisible) {
      props.historyVehicleSelect(listVehicleId, true);
    } else {
      props.historyVehicleSelect(listVehicleId, false);
    }
  };

  const loadMore = () => {
    props.basketFetchMore("ALL");
  };

  const goBack = () => {
    navigate(-1);
  };

  const {
    vehicleIds,
    loading,
    selectedVehicleId,
    offset,
    totalCount,
    more,
    hasActiveFilter,
    config,
  } = props;

  const vehiclesListHeaderProps = {
    goBack,
    totalCount,
    loading,
    handleFilterChange: () => props.basketFetchWebTest("ALL"),
  };

  const listVehiclesProps = {
    loading,
    vehicleIds,
    loadMore,
    more,
    onListVehicleClick,
    selectedVehicleId,
    offset,
  };

  const historySidebarProps = {
    goBack,
    config,
    handleCriteriaChanged: () => props.basketFetchWebTest("ALL"),
  };

  const showNoVehicleBiddings =
    totalCount === "0" && !loading && !hasActiveFilter;

  if (showNoVehicleBiddings) {
    return (
      <div className="mt-10">
        <InfoContainer
          icon="fa fa-gavel"
          text={t("Currently no vehicles with biddings to show") + "..."}
        />
      </div>
    );
  }

  return (
    <div className="flex h-full gap-x-3">
      <div className="hidden h-full xl:block xl:w-3/12 3xl:w-2/12">
        <HistorySidebar {...historySidebarProps} />
      </div>

      <div className="flex h-full w-full flex-col 2xl:w-5/12">
        <VehiclesListHeader {...vehiclesListHeaderProps} />
        <ListVehicles {...listVehiclesProps} />
      </div>

      <div className="hidden h-full 3xl:block 3xl:w-5/12">
        <div className="bg-normal sticky top-0 h-full w-full md:top-[70px]">
          <Scrollbars>
            <ListVehicleState
              containerId={selectedVehicleId}
              listVehicleId={selectedVehicleId}
              showStickyHeader={false}
            />
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vehicleIds: basketSelectors.getIds(state, "ALL"),
    loading: basketSelectors.getLoading(state, "ALL"),
    offset: basketSelectors.getOffset(state, "ALL"),
    more: basketSelectors.getMore(state, "ALL"),
    totalCount: basketSelectors.getTotalCount(state, "ALL"),
    hasActiveFilter: hasActiveCriteria(state, -1),
  };
};

const ConnectedComponent = compose(
  withConfig,
  connect(mapStateToProps, {
    historyVehicleSelect,
    basketFetchWebTest,
    basketFetchMore,
  })
)(HistoryVehicles);

export { ConnectedComponent as HistoryVehicles };
